import { Layout, Menu, MenuProps } from "antd";
import { Content, Header } from "antd/es/layout/layout";
import React, { PropsWithChildren } from "react";
import { MENU_BAR_ITEMS } from "../../util/constants/menu";
import { useAppContext } from "../../util/context/AppContext";

const AppLayout: React.FC<PropsWithChildren> = ({
  children,
}: PropsWithChildren) => {
  const { activeSection, updateActiveSection } = useAppContext();

  return (
    <div className="overflow-x-clip">
      <Layout>
        <Header
          style={{
            position: "sticky",
            top: 0,
            zIndex: 1000,
          }}
        >
          <Menu
            mode="horizontal"
            defaultSelectedKeys={[MENU_BAR_ITEMS[0]]}
            items={MENU_BAR_ITEMS.map((label: string) => ({
              key: label,
              label,
            }))}
            selectedKeys={[activeSection]}
            onSelect={
              ((info: any) => {
                if (!info) {
                  return;
                }

                const elem = document.getElementById(info.key);
                elem?.scrollIntoView({ behavior: "smooth", block: "start" });
                updateActiveSection(info.key);
              }) as MenuProps["onSelect"]
            }
          />
        </Header>
        <Content>{children}</Content>
      </Layout>
    </div>
  );
};

export default AppLayout;
