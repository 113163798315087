import { Divider, Tag, theme, Typography } from "antd";
import { motion } from "framer-motion";
import { ABOUT_ME_TAGS } from "../../util/constants/aboutMeConstants";
import "../../util/styles/component-stylesheets/about-me.css";
import { AboutMeTag } from "../../views/aboutMeTag";

const AboutMe: React.FC = () => {
  const { Title, Text } = Typography;
  const { useToken } = theme;
  const { token } = useToken();
  return (
    <>
      <motion.div
        initial={{ opacity: 0, scale: 1 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 1.5 }}
        className="about-me flex flex-col xl:flex-row items-center gap-20"
      >
        <div className="about-me-text-container">
          <Title
            level={3}
            style={{
              color: token.colorTextSecondary,
              letterSpacing: 4,
              fontWeight: "bold",
            }}
          >
            LAM CHENG HOU
          </Title>
          <Divider />
          <Title level={1} className="rainbow-title">
            Aspiring Full Stack Software Engineer
          </Title>
          {ABOUT_ME_TAGS.map((tag: AboutMeTag, idx: number) => (
            <Tag icon={tag.tagIcon} key={idx}>
              {tag.tagLabel}
            </Tag>
          ))}{" "}
          <br />
          <br />
          <Text className="about-me-desc">
            Hi! I'm Cheng Hou, a penultimate Computer Science student at the
            National University of Singapore, let me take you around my past
            projects and experiences as a budding engineer!
          </Text>
        </div>
        <div>
          <img
            src="chenghoupic.jpg
          "
            className="about-me-img"
            alt="potrait"
          />
        </div>
      </motion.div>
    </>
  );
};

export default AboutMe;
