import { SkillsCategory } from "../../views/skillsCategory";

export const SKILL_CATEGORIES: SkillsCategory[] = [
  {
    title: "Frontend Languages, Frameworks & Libraries",
    images: [
      "javascript-ico.jpg",
      "typescript-ico.jpg",
      "html-ico.jpg",
      "css-ico.jpg",
      "sass-ico.jpg",
      "react-ico.jpg",
      "redux-ico.jpg",
      "antd-ico.jpg",
      "mui-ico.jpg",
    ],
    backgroundColor: "rgba(255, 105, 97, 0.20)",
  },
  {
    title: "Backend Languages, Frameworks & Libraries",
    images: [
      "csharp-ico.jpg",
      "python-ico.jpg",
      "java-ico.jpg",
      "typescript-ico.jpg",
      "dotnet-ico.jpg",

      "mssqlserver-ico.jpg",
      "postgres-ico.jpg",
      "mongo-ico.jpg",
      "rprog-ico.jpg",
      "go-ico.jpg",
    ],
    backgroundColor: "rgba(255, 192, 103, 0.20)",
  },
  {
    title: "AI/ML/Data/Graph related Libraries and Languages",
    images: [
      "numpy-ico.jpg",
      "pandas-ico.jpg",
      "pytorch-logo.jpg",

      "matplotlib-logo.jpg",
      "seaborn-logo.jpg",
    ],
    backgroundColor: "rgba(224, 214, 255, 0.20)",
  },
  {
    title: "Version Control, Testing, and other Development Tools",
    images: ["git-ico.jpg", "junit-ico.jpg"],
    backgroundColor: "rgba(179, 235, 242, 0.20)",
  },
];
