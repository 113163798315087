import { Experience } from "../../views/experience";
import "../../util/styles/component-stylesheets/experience-card.css";
import { APP_COLORS_DARK } from "../../util/styles/theme";
import ImagePlaceholder, {
  ImagePlaceHolderProps,
} from "../images/ImagePlaceholder";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";

const ExperienceCard: React.FC<Experience> = ({
  title,
  org,
  orgImage = "",
  subtitle = "",
  images,
  description,
  bulletPoints = [],
  cardLeft = true,
}: Experience) => {
  const [viewportWidth, setViewportWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const card = (
    <motion.div
      initial={{ opacity: 0, x: cardLeft ? -50 : 50 }}
      whileInView={{ opacity: 1, x: 0 }}
      viewport={{ amount: 0.05, once: true }}
      transition={{ duration: 0.75 }}
    >
      <div
        style={{
          transform: `rotate(${cardLeft ? "-" : ""}1.5deg)`,
          backgroundColor: APP_COLORS_DARK.CONTRAST,
          color: APP_COLORS_DARK.MENU_BLACK,
        }}
        className="experience-card flex flex-col"
      >
        <img
          src={`experiences/${orgImage}`}
          alt={orgImage}
          className="org-img self-center"
        />
        <br />
        <span
          style={{ color: APP_COLORS_DARK.DARK_BLUE }}
          className="experience-title"
        >
          {title}
        </span>
        <span
          className="experience-subtitle"
          style={{ color: APP_COLORS_DARK.DARK_BLUE }}
        >
          {org}
        </span>
        <span className="experience-subtitle mb-5">{subtitle}</span>
        <div className="experience-images">
          {images.map((img: ImagePlaceHolderProps, idx: number) => (
            <ImagePlaceholder {...img} key={idx} />
          ))}
        </div>
      </div>
    </motion.div>
  );

  const text = (
    <motion.div
      initial={{ opacity: 0, x: cardLeft ? 50 : -50 }}
      whileInView={{ opacity: 1, x: 0 }}
      viewport={{ amount: 0.05, once: true }}
      transition={{ duration: 0.75 }}
    >
      <div
        style={{ position: "relative", left: 0 }}
        className="experience-box experience-description"
      >
        <span className="">{description}</span>
        <br />
        <br />
        <ul>
          {bulletPoints.map((pt: string, idx: number) => (
            <div key={idx}>
              <li>{pt}</li>
              <br />
            </div>
          ))}
        </ul>
      </div>
    </motion.div>
  );

  return (
    <div className="flex flex-row flex-wrap mt-10">
      {cardLeft || viewportWidth < 1200 ? card : text}
      <br />
      {cardLeft || viewportWidth < 1200 ? text : card}
    </div>
  );
};

export default ExperienceCard;
