import { RouteObject } from "react-router";
import HomePage from "../pages/homepage";
import AppLayout from "../components/layout/appLayout";

export const APP_ROUTES: RouteObject[] = [
  {
    path: "/",
    element: <HomePage />,
  },
];

export const wrapRoutes = (routes: RouteObject[]): RouteObject[] => {
  return routes.map((route: RouteObject) => {
    const children = route.children;

    if (children) {
      route.children = wrapRoutes(children);
    }

    return {
      ...route,
      // Wrap any layout elements here
      element: <AppLayout>{route.element}</AppLayout>,
    };
  });
};
