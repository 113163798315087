import { Divider, Typography } from "antd";
import { EXPERIENCES } from "../../util/constants/experiencesConstants";
import { Experience } from "../../views/experience";
import ExperienceCard from "./ExperienceCard";

const Experiences: React.FC = () => {
  const { Title } = Typography;

  return (
    <div className="skills">
      <Title level={1}>Experiences</Title>
      <Divider />
      {EXPERIENCES.map((exp: Experience, idx: number) => (
        <ExperienceCard {...exp} key={idx} />
      ))}
    </div>
  );
};

export default Experiences;
