import { ConfigProvider } from "antd";
import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { APP_ROUTES, wrapRoutes } from "./routing/routes";
import { ANTD_THEME_CONFIG_DARK } from "./util/styles/theme";
import { AppProvider } from "./util/context/AppContext";

function App() {
  const router = createBrowserRouter(wrapRoutes(APP_ROUTES));

  return (
    <AppProvider>
      <ConfigProvider theme={ANTD_THEME_CONFIG_DARK}>
        <RouterProvider router={router} />
      </ConfigProvider>
    </AppProvider>
  );
}

export default App;
