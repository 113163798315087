import { theme, ThemeConfig } from "antd";

export const APP_COLORS_DARK: { [key: string]: string } = {
  "MENU_BLACK": '#141414',
  "CONTENT_GRAY": "#212121",
  "DARK_BLUE": "#1338BE",
  "CONTRAST": "#F2EBDB",
}

export const ANTD_THEME_CONFIG_DARK: ThemeConfig = {
  token: {
    colorTextSecondary: APP_COLORS_DARK.DARK_BLUE,
    fontFamily: "Montserrat",
    fontSize: 16,
  },
  algorithm: theme.darkAlgorithm,
  components: {
    Layout: {
      headerBg: APP_COLORS_DARK.MENU_BLACK,
      bodyBg: APP_COLORS_DARK.CONTENT_GRAY,
    },
  }
}