import React, { ReactNode, useEffect, useState } from "react";
import AboutMe from "../components/portfolio/AboutMe";
import { motion } from "framer-motion";
import { DownCircleTwoTone } from "@ant-design/icons";
import { MENU_BAR_ITEMS } from "../util/constants/menu";
import { useAppContext } from "../util/context/AppContext";
import Skills from "../components/portfolio/Skills";
import Experiences from "../components/portfolio/Experiences";
import ProjectsTemp from "../components/portfolio/ProjectsTemp";
import Projects from "../components/portfolio/Projects";

const HomePage: React.FC = () => {
  const { activeSection, updateActiveSection } = useAppContext();
  const sectionToNodeMap: {
    [key: string]: ReactNode;
  } = {
    "About Me": <AboutMe />,
    Skills: <Skills />,
    Experiences: <Experiences />,
    "Projects & Coursework": <Projects />,
  };

  // Scroll behavior
  const [prevScrollPosition, setPrevScrollPosition] = useState(0);
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const scrollDirectionDown = scrollPosition > prevScrollPosition;
      setPrevScrollPosition(scrollPosition);

      MENU_BAR_ITEMS.forEach((section: string, idx: number) => {
        const element = document.getElementById(section);
        const isLast = idx === MENU_BAR_ITEMS.length - 1;
        if (!element) {
          return;
        }
        const elementHeight = element.clientHeight;
        const top = element.offsetTop;

        // Scroll up behaviour

        if (
          !scrollDirectionDown &&
          scrollPosition <= top + (7 * elementHeight) / 8 &&
          scrollPosition >= top &&
          !isLast
        ) {
          updateActiveSection(section);
          return;
        }

        // Scroll down behaviour
        if (scrollPosition > top + (7 * elementHeight) / 8 && !isLast) {
          const nextSection = MENU_BAR_ITEMS[idx + 1];
          const nextElement = document.getElementById(nextSection);
          const nextElementTop = nextElement?.offsetTop ?? 0;

          if (scrollPosition <= nextElementTop) {
            updateActiveSection(nextSection);
          }
        } else if (
          scrollPosition >= top &&
          scrollPosition <= top + (7 * elementHeight) / 8
        ) {
          updateActiveSection(section);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  });

  return (
    <>
      {MENU_BAR_ITEMS.map((ky: string, idx: number) => (
        <div key={ky} id={ky}>
          {sectionToNodeMap[ky]}

          {idx !== MENU_BAR_ITEMS.length - 1 && (
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={
                activeSection === ky
                  ? {
                      opacity: 0.75,
                      y: [50, -20, 10, 0],
                      transition: { delay: 5 },
                    }
                  : {
                      y: 0,
                      opacity: 0,
                      transition: { delay: 0, ease: "easeOut" },
                    }
              }
              className="flex justify-center"
              id={`${ky}-motiondiv`}
            >
              <DownCircleTwoTone />
            </motion.div>
          )}
        </div>
      ))}
    </>
  );
};

export default HomePage;
