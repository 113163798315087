import { SkillsCategory } from "../../views/skillsCategory";
import "../../util/styles/component-stylesheets/skills-card.css";
import { Flex, Typography } from "antd";

const SkillsCard: React.FC<SkillsCategory> = ({
  title,
  images,
  width = "fit-content",
  height = "fit-content",
  backgroundColor = "gray",
}: SkillsCategory) => {
  const { Title } = Typography;
  return (
    <div style={{ width, height, backgroundColor }} className="skills-card">
      <Title level={2}>{title}</Title>
      <Flex wrap gap={15} className="mt-10">
        {images.map((img: string, idx: number) => (
          <img src={`skills/${img}`} alt={img} width="80px" key={idx} />
        ))}
      </Flex>
    </div>
  );
};

export default SkillsCard;
