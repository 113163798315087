import { useState } from "react";
import "../../util/styles/component-stylesheets/image-placeholder.css";
import ImageModal from "./ImageModal";

// For mini components we keep the props here
export interface ImagePlaceHolderProps {
  thumbnail: string;
  image: string;
  modalTitle: string;
  modalDescription?: string;
  width?: string;
  modalWidth?: number;
}

const ImagePlaceholder: React.FC<ImagePlaceHolderProps> = ({
  image,
  thumbnail,
  modalTitle,
  modalDescription = "",
  width = "100%",
  modalWidth = 1000,
}: ImagePlaceHolderProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  return (
    <div style={{ width }} className="image-placeholder">
      <img src={thumbnail} alt={thumbnail} className="rounded-xl" />
      <p
        className="see-more-text w-full h-full"
        onClick={() => setIsModalOpen(true)}
      >
        Click to see more
      </p>
      <ImageModal
        image={image}
        title={modalTitle}
        description={modalDescription}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        modalWidth={modalWidth}
      />
    </div>
  );
};

export default ImagePlaceholder;
