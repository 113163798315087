import { Modal } from "antd";
import "../../util/styles/component-stylesheets/experience-card.css";

// For mini components we keep the props here
export interface ImageModalProps {
  image: string;
  title: string;
  description?: string;
  isModalOpen: boolean;
  setIsModalOpen: (_: boolean) => void;
  modalWidth: number;
}

const ImageModal: React.FC<ImageModalProps> = ({
  image,
  title,
  description = "",
  isModalOpen,
  setIsModalOpen,
  modalWidth,
}: ImageModalProps) => {
  return (
    <Modal
      open={isModalOpen}
      title={title}
      onCancel={() => setIsModalOpen(false)}
      width={modalWidth}
      cancelText="Close"
      okButtonProps={{ style: { display: "none" } }}
    >
      <img
        src={image}
        alt={image}
        className="rounded-xl mb-5 ml-auto mr-auto"
      />
      <span className="experience-description">{description}</span>
    </Modal>
  );
};

export default ImageModal;
