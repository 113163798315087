import { Experience } from "../../views/experience";

export const EXPERIENCES: Experience[] = [
  {
    title: "Software Engineer (Full Stack)",
    org: "PhillipCapital (Phillip Nova)",
    orgImage: "phillip-logo.jpg",
    subtitle: `May 2024 - Aug 2024 (Internship),
Sept 2024 (Part Time Contract)`,
    images: [
      {
        thumbnail: "experiences/tradeanalytics-pic1-thumbnail.jpg",
        image: "experiences/tradeanalytics-pic1.jpg",
        modalTitle: "Trade Analytics Dashboard",
        modalDescription:
          "A snippet of the trade analytics dashboard which I built using Angular, Angular Material, Typescript, HTML and CSS!",
      },
      {
        thumbnail: "experiences/tradeanalytics-pic2-thumbnail.jpg",
        image: "experiences/tradeanalytics-pic2.jpg",
        modalTitle: "Learning Angular and .NET!",
        modalDescription:
          "During the requirements gathering and research phase, I developed Proof-Of-Concepts (POC) applications for my supervisors to view. This allowed me to pick up Angular and .NET fairly quickly!",
      },
      {
        thumbnail: "experiences/phillip-presenting-thumbnail.jpg",
        image: "experiences/phillip-presenting.jpg",
        modalTitle: "Presentation!",
        modalDescription:
          "Presented my work to various stakeholders, directors, CEO and fellow interns!",
        modalWidth: 850,
      },
      {
        thumbnail: "experiences/intern-friends-thumbnail.jpg",
        image: "experiences/intern-friends.jpg",
        modalTitle: "Intern Friends",
        modalDescription:
          "I was lucky to have quite a large group of fellow interns.",
        modalWidth: 500,
      },
    ],
    description:
      "At PhillipCapital, I learnt a wide range of financial concepts from meetings with stakeholders, and also picked up the Angular frontend framework (Typescript) and .NET backend framework (C#). I was the main developer for 2 full stack projects:",
    bulletPoints: [
      "Developed the frontend and backend for a trade analytics dashboard, which displays to clients of Phillip Nova an overview and details of their trades, such as return rate and profit & loss ratio. This was done using Angular, .NET and MS SQL Server.",
      "Developed a two-factor authentication service in the form of a ASP.NET Web API, which generates and verifies One-Time Pin based on requests sent from the company's application, helping to reduce spam and fradulent activities on the company's website.",
    ],
  },
  {
    title: "Software Engineer Intern",
    org: "Synapxe",
    orgImage: "synapxe-logo.jpg",
    subtitle: "Jan 2024 - May 2024",
    images: [
      {
        thumbnail: "experiences/synapxe-app-pic1-thumbnail.jpg",
        image: "experiences/synapxe-app-pic1.jpg",
        modalTitle: "Healthcare Management System",
        modalDescription:
          "This form was dynamically generated using the data models fetched from the backend. Built using React Typescript!",
      },
      {
        thumbnail: "experiences/synapxe-app-pic2-thumbnail.jpg",
        image: "experiences/synapxe-app-pic2.jpg",
        modalTitle: "Healthcare Management System",
        modalDescription:
          "The form supports various field types such as dropdowns and multi-valued inputs, and features such as multiplicity.",
      },
      {
        thumbnail: "experiences/synapxe-last-day-thumbnail.jpg",
        image: "experiences/synapxe-last-day.jpg",
        modalTitle: "My team!",
        modalDescription:
          "I was the only intern in my team, but I really enjoyed my time with them!",
      },
      {
        thumbnail: "experiences/synapxe-recommendation-letter-thumbnail.jpg",
        image: "experiences/synapxe-recommendation-letter.jpg",
        modalTitle: "Recommendation Letter (Head of Software Engineering)",
        modalDescription: "",
        modalWidth: 600,
      },
    ],
    description:
      "At Synapxe, I was mainly a frontend developer, where I greatly honed my frontend development skills in React Typescript. I worked with important frameworks such as Redux, and learned the intricacies of concepts such as state management and localStorage. I was part of 2 projects where I:",
    bulletPoints: [
      "Developed the frontend for a healthcare dashboard, that supports CRUD of healthcare data such as patient records. Part of the process involves dynamic generation of forms through models fetched from the backend.",
      "Developed a prototype for an internally used billing system.",
    ],
    cardLeft: false,
  },
  {
    title: "Full Stack Software Engineer",
    org: "Computing for Voluntary Welfare Organisations (CVWO)",
    orgImage: "cvwo-logo.jpg",
    subtitle: `May 2023 - Aug 2023 (Internship),
Sep 2023 - Sep 2024 (Exco)`,
    images: [
      {
        thumbnail: "experiences/sidebar-reordering-pic1-thumbnail.jpg",
        image: "experiences/sidebar-reordering-pic1.jpg",
        modalTitle: "Sidebar Reordering Panel (with hidden area)",
        modalDescription:
          "A sidebar drag-and-drop reordering and customisation panel. It has a file-folder structure, where groups of pages can be parked under a folder, or it can stand by itself. The icons and names of each page item can be customised to the admin's liking. If a page is to be hidden, the sidebar can be updated accordingly.",
      },
      {
        thumbnail: "experiences/sidebar-reordering-pic2-thumbnail.jpg",
        image: "experiences/sidebar-reordering-pic2.jpg",
        modalTitle: "Sidebar Reordering Panel",
        modalDescription:
          "The sidebar customisation panel is responsive and supports drag-and-drop, providing users with a seamless experience. The style of the panel also provides coherence with the application's style.",
      },
      {
        thumbnail: "experiences/module-toggling-thumbnail.jpg",
        image: "experiences/module-toggling.jpg",
        modalTitle: "Module Toggling Panel",
        modalDescription:
          "The module toggling panel allows functionalities of the application to be switched on and off. As some modules depend on others, a warning modal is implemented to help prevent accidental changes.",
      },
      {
        thumbnail: "experiences/cornerstone-thumbnail.jpg",
        image: "experiences/cornerstone.jpg",
        modalTitle: "Team Cornerstone!",
        modalWidth: 700,
      },
    ],
    description:
      "At CVWO: a full stack internship programme where I had the opportunity to develop large-scale web applications used by social service agencies around Singapore, I was part of Project Cornerstone. The project aimed to modularise existing applications, and provides the ability to create and customise new functional custom pages on the app. Here are some features I undertook:",
    bulletPoints: [
      "Developed the frontend for a module-toggling panel, allowing users to toggle modules and their dependencies.",
      "Developed the frontend of a sidebar drag-and-drop reordering and customisation panel, allowing administrators to order the pages in the sidebar and group pages into submenus.",
      "Integrated CRUD permissions (Role-Based Access Control, RBAC) into custom-made pages on the application, allowing administrators to dictate what actions different users can perform on the app. (BE & FE)",
      "Developed the frontend and backend for form field-level permissions (RBAC) which are the read/write permissions the user has for individual fields within forms of the application. For instance, it can be set such that Volunteers cannot edit Patient records.",
    ],
  },
];
