import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
} from "react";
import { MENU_BAR_ITEMS } from "../constants/menu";

const AppContext = createContext({
  activeSection: "",
  updateActiveSection: (_: string) => {},
});

export const useAppContext = () => useContext(AppContext);

export const AppProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [activeSection, setActiveSection] = useState<
    (typeof MENU_BAR_ITEMS)[number]
  >(MENU_BAR_ITEMS[0]);

  const updateActiveSection = (section: string) => setActiveSection(section);

  const contextValue = {
    activeSection,
    updateActiveSection,
  };

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};
