import { Divider, Flex, Typography } from "antd";
import "../../util/styles/component-stylesheets/skills.css";
import SkillsCard from "./SkillsCard";
import { SKILL_CATEGORIES } from "../../util/constants/skillsConstants";
import { SkillsCategory } from "../../views/skillsCategory";

const Skills: React.FC = () => {
  const { Title } = Typography;

  return (
    <div className="skills">
      <Title level={1}>Skills</Title>
      <Divider />

      <Flex wrap gap={20}>
        {SKILL_CATEGORIES.map((cat: SkillsCategory, idx: number) => (
          <SkillsCard {...cat} key={idx} />
        ))}
      </Flex>
    </div>
  );
};

export default Skills;
