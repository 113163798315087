import { Divider, Typography } from "antd";
import "../../util/styles/component-stylesheets/skills.css";
import { APP_COLORS_DARK } from "../../util/styles/theme";

const Projects: React.FC = () => {
  const { Title } = Typography;

  return (
    <div className="skills">
      <Title level={1}>Projects & Coursework</Title>
      <Divider />
      Working to bring my projects to live here... In the meantime, check out my{" "}
      <a
        href="https://github.com/lamchenghou"
        style={{ color: APP_COLORS_DARK.DARK_BLUE }}
      >
        Github
      </a>{" "}
      where you can find the repository for this website too!
      <ul>
        <li></li>
      </ul>
      <div style={{ height: "40vh" }} />
    </div>
  );
};

export default Projects;
